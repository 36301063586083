import React, { useContext } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FormContext } from "../contexts/FormContext";
import { formStyles } from "../styles/formStyle";

// Menu displaying pcm versions to use, possibly other routing apis
// in future build
export default function VersionMenu(params) {
  const { formData, setFormData } = useContext(FormContext);
  const classes = formStyles();

  return (
    <Grid item style={{ marginTop: ".5rem" }}>
      <FormControl className={classes.versionFormControl}>
        <InputLabel
          id="pcm-version-select-label"
          style={{ color: "#9da1a7" }}

        >
          Version
        </InputLabel>
        <Select
          className={classes.lightSelect}
          MenuProps={{
            PaperProps: {
              className:classes.lightMenuSelect,
            },
          }}
          value={formData.pcmVersion.value}
          onChange={(e) =>
            setFormData({
              ...formData,
              pcmVersion: { value: e.target.value },
            })
          }
          label={"Version"}
        >
          <MenuItem value={"Current"}>Current</MenuItem>
          <MenuItem value={"PCM33"}>PCMiler 33</MenuItem>
          <MenuItem value={"PCM32"}>PCMiler 32</MenuItem>
          <MenuItem value={"PCM31"}>PCMiler 31</MenuItem>
          <MenuItem value={"PCM30"}>PCMiler 30</MenuItem>
          <MenuItem value={"PCM29"}>PCMiler 29</MenuItem>
          <MenuItem value={"PCM28"}>PCMiler 28</MenuItem>
          <MenuItem value={"PCM27"}>PCMiler 27</MenuItem>
          <MenuItem value={"PCM26"}>PCMiler 26</MenuItem>
          <MenuItem value={"PCM25"}>PCMiler 25</MenuItem>
          <MenuItem value={"PCM24"}>PCMiler 24</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}
