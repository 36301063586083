import React, { createContext, useState, useReducer } from "react";
import formReducer from "../reducers/form.reducer";

export const FormContext = createContext({});

export const initialFormData = {
  origin: { value: "", error: false },
  destination: { value: "", error: false },
  tolls: { value: false },
  openBorders: { value: true },
  pcmVersion: { value: "Current" },
  routeType: {value: "Practical"}
};

export function FormProvider(props) {
  // reducer currently used for extra field data
  const [fields, dispatch] = useReducer(formReducer, [], undefined);

  // TODO: changed these hooks into a reducer and combine with above reducer
  const [formData, setFormData] = useState(initialFormData);
  const [resultData, setResultData] = useState(null);
  const [isMapHidden, setIsMapHidden] = useState(true);
  const [isAccelerateFormHidden, setIsAccelerateFormHidden] = useState(false)

  return (
    <FormContext.Provider
      value={{
        fields,
        dispatch,
        formData,
        setFormData,
        resultData,
        setResultData,
        isMapHidden,
        setIsMapHidden,
        isAccelerateFormHidden,
        setIsAccelerateFormHidden
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
}
