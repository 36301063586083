import React, {useState, useContext} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import ThemeSlider from "./ThemeSlider";
import { ThemeContext } from "../contexts/ThemeContext";
import { bannerStyles } from "../styles/bannerStyles";
import darkLogo from "../images/dark-logo.svg"
import lightLogo from "../images/light-logo.svg"

// TODO: Break down in to smaller components & migrate stateful hooks to a reducer
export default function Banner() {
  const classes = bannerStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [userInfo, setUserInfo] = useState(null);

  const { isDarkMode } = useContext(ThemeContext);
  // const { authService, authState } = useOktaAuth();

  const primary = isDarkMode ? "#fff" : "#2aaae2";
  const background = isDarkMode ? "#2f3032" : "#fff";

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // useEffect( () => {
  //   if (!authState.isAuthenticated) {
  //     setUserInfo(null);
  //   } else {
  //     authService.getUser().then( info => {
  //       setUserInfo(info)
  //     })
  //   }
  // }, [authService, authState])

  // Desktop Menu on avatar click
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{ style: { background: background, color: primary } }}
    >
      <MenuItem>
        {/* darkmode/lightmode toggle component on Desktop*/}
        <ThemeSlider />
      </MenuItem>
      {/*<MenuItem onClick={handleMenuClose} style={{ justifyContent: "center" }}>*/}
      {/*  Logout*/}
      {/*</MenuItem>*/}
    </Menu>
  );

  // Mobile Menu on avatar click
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{ style: { background: background, color: primary } }}
    >
      <MenuItem>
        {/* darkmode/lightmode toggle component on mobile*/}
        <ThemeSlider />
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        {/*<p>Logout</p>*/}
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ background: background }}>
        <Toolbar>
          {/* Branding/Logo Area, for desktop */}
          <img src={isDarkMode ? darkLogo : lightLogo} alt={"Arrive-Logistics"} width={"150px"} height={"50px"}/>

          <div className={classes.grow} />

          <Typography
            className={classes.title}
            style={{ color: primary }}
            variant="h6"
            noWrap
          >
            QuickMiles
          </Typography>

          <div className={classes.sectionDesktop}>
            {/* Open Menu button Desktop */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle fontSize="large" style={{ color: primary }} />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <div className={classes.grow} />

            {/* AppName Logo area for mobile */}
            <Typography
              className={
                isDarkMode ? classes.darkMobileTitle : classes.lightMobileTitle
              }
            >
              QuickMiles
            </Typography>

            {/* Open Mobile Menu button */}
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon
                className={
                  isDarkMode ? classes.darkMobileMenu : classes.lightMobileMenu
                }
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
