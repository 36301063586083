import { makeStyles } from "@material-ui/styles";

export const bannerStyles = makeStyles((theme) => ({
  palette: {
    secondary: {
      main: '#2aaae2'
    }
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  lightMobileMenu: {
    color: "#292929",
  },
  darkMobileMenu: {
    color: "#efefef",
  },

  lightMobileTitle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      color: "#2aaae2",
      alignSelf: "center",
    },
  },

  darkMobileTitle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      color: "#efefef",
      alignSelf: "center",
    },
  },
}));
