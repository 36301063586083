import { makeStyles } from "@material-ui/styles";

export const formStyles = makeStyles((theme) => ({
  // General styles not affected by theme
  root: {
    marginLeft: "1rem",
    marginTop: "2rem",
  },
  formControl: {
    minWidth: 250,
    marginTop: ".5rem",
  },

  versionFormControl: {
    minWidth: 250,
    marginTop: "-1rem",
  },

  //Form Field Styles
  lightFormField: {
    color: "#2f3032",
    //add some classes
  },

  lightAddFieldButton: {
    color: "#2aaae2",
    textTransform: "none",
    "&:hover": { backgroundColor: "#fff", color: "#b1e1f5" },
  },

  lightAddButton: {
    color: "#2aaae2",
  },

  //SubmitButton Styles
  lightButton: {
    marginTop: "1rem",
    background: "#2aaae2",
    color: "#fff",
    "&:hover": { backgroundColor: "#2aaae2" },
  },

  //RouteType Radio buttons
  radioLegend: {
    color: "#9da1a7",
    paddingBottom: ".5rem",
    "&.Mui-focused": {
      color: "#9da1a7"
    }
  },

  lightRadioButtonLabel: {
    color: "#9da1a7",
    size: "1px",
    "&.Mui-focused": {
      color: "#2f3032"
    },
  },

  lightRadioButton: {
    color: "#9da1a7",
  },


  //SelectField
  sliderLabel: {
    fontSize: "11px",
  },

  lightSelect: {
    color: "#9da1a7",
    "&:before": {
      // normal
      borderBottom: "1px solid #9da1a7",
    },
    "&:after": {
      // focused
      borderBottom: "2px solid #2aaae2",
    },
    "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
      // hover
      borderBottom: "2px solid #9da1a7",
    },
  },

  lightMenuSelect: {
    color: "#9da1a7",
    background: "#fff",
  },
}));
