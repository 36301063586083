import React, {useContext} from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {FormContext} from "../contexts/FormContext";
import {formStyles} from "../styles/formStyle";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/styles";

const StyledFormLabel = withStyles({
  root: {
    color: "#9da1a7",
    "&.Mui-focused": {
      color: "#9da1a7"
    },
  }
})(FormLabel);


export default function RouteType() {
  //contexts
  const {formData, setFormData} = useContext(FormContext)

  //styles
  const classes = formStyles();
  const radioButtonLabel = classes.lightRadioButtonLabel;
  const radioButton = classes.lightRadioButton;
  const radioColor = "secondary";

  const handleChange = (e) => {
    let currState = {...formData}
    setFormData({...currState, routeType: {value: e.target.value}})
  }

  return (
    <FormControl component={"fieldset"} style={{marginTop: "1rem"}}>
      <StyledFormLabel className={classes.radioLegend}
                 component="legend"
      >
        Route Type
      </StyledFormLabel>
      <RadioGroup row name="route-type" value={formData.routeType.value}>
        <FormControlLabel
          className={radioButtonLabel}
          value="Practical"
          control={<Radio className={radioButton} color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Practical</Typography>}
        />
        <FormControlLabel
          className={radioButtonLabel}
          value="Shortest"
          control={<Radio className={radioButton} color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Shortest</Typography>}
        />
        <FormControlLabel
          className={radioButtonLabel}
          value="Fastest"
          control={<Radio className={radioButton} color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Fastest</Typography>}
        />
      </RadioGroup>
    </FormControl>
  )
}