import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/styles";

export const StyledRadio = withStyles({
    colorPrimary: {
      color: "#9da1a7"
    },
    colorSecondary: {
      color: "#9da1a7"
    }
})(Radio);
