import React, { useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { FormContext } from "../contexts/FormContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { LightTextField, DarkTextField } from "./StyledTextField";


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
    marginTop: ".5rem",
  },
  lightFormField: {
    color: "#2f3032",
  },
  darkFormField: {
    color: "#efefef",
  },
  darkFormErrorText: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "#ffedf1"
    }
  }
}));

// extra stops are generated via "add a stop" button on
// the form component and rendered dynamically
export default function ExtraStops() {
  const { fields, dispatch } = useContext(FormContext);
  const { isDarkMode } = useContext(ThemeContext);

  const classes = useStyles();
  const StyledTextField = isDarkMode ? DarkTextField : LightTextField;

  return fields.map((field) => {
    return (
      <React.Fragment key={field.id}>
        <Grid item>
          <StyledTextField
            error={field.error}
            helperText={field.error ? 'Address entered was not found': null}
            FormHelperTextProps={isDarkMode ? {className: classes.darkFormErrorText}: null}
            className={classes.formControl}
            inputProps={{
              className: isDarkMode
                ? classes.darkFormField
                : classes.lightFormField,
            }}
            label="Additional Stop"
            id={field.id}
            value={field.value}
            onChange={(e) =>
              dispatch({ type: "UPDATE", id: field.id, value: e.target.value })
            }
          />

          <IconButton
            style={{ verticalAlign: "bottom" }}
            onClick={() => dispatch({ type: "DELETE", id: field.id })}
          >
            <DeleteForeverOutlinedIcon
              style={
                isDarkMode
                  ? { color: "#fff", alignSelf: "bottom" }
                  : { color: "#9da1a7" }
              }
            />
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  });
}
