import React from "react";
import {FormProvider} from "../contexts/FormContext";
import {MainTheme} from "../contexts/ThemeContext";
import {Container, Grid} from "@material-ui/core";
import Banner from "./Banner";
import MobileDataDisplay from "./MobileDataDisplay";
import PageContent from "./PageContent";
import Form from "./Form";
import Map from "./Map";
import DataDisplay from "./DataDisplay";

export default function () {
    return (
        // <ThemeProvider>
      <MainTheme>
            <PageContent>
                <Container xs={12} maxWidth="md" style={{ paddingTop: ".5rem" }}>
                    <Banner />
                    <Grid container justify="center">
                        <FormProvider>
                            <Grid item sm={false}>
                                <MobileDataDisplay />
                            </Grid>
                            <Grid item sm={6}>
                                <Form />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                <DataDisplay />
                                <Map/>
                            </Grid>
                        </FormProvider>
                    </Grid>
                </Container>
            </PageContent>
      </MainTheme>
        // </ThemeProvider>
    )

}