import React, {useContext} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import {FormContext} from "../contexts/FormContext";
import {formStyles} from "../styles/formStyle";
import {ThemeContext} from "../contexts/ThemeContext";
import {StyledRadio} from "./StyledRadio";
import {withStyles} from "@material-ui/styles";

const DarkStyledFormLabel = withStyles({
  root: {
    color: "#9da1a7",
    "&.Mui-focused": {
      color: "#efefef"
    },
  },
})(FormLabel);

const LightStyledFormLabel = withStyles({
  root: {
    color: "#9da1a7",
    "&.Mui-focused": {
      color: "#2aaae2"
    },
  }
})(FormLabel);

export default function RouteType() {
  //contexts
  const {formData, setFormData} = useContext(FormContext)
  const {isDarkMode} = useContext(ThemeContext)

  //styles
  const classes = formStyles();
  let radioButtonLabel = isDarkMode ? classes.darkRadioButtonLabel : classes.lightRadioButtonLabel;
  let radioButton = isDarkMode ? classes.darkRadioButton : classes.lightRadioButton;
  let radioColor = isDarkMode ? "primary" : "secondary";
  const StyledFormLabel = isDarkMode ? DarkStyledFormLabel : LightStyledFormLabel;

  const handleChange = (e) => {
    let currState = {...formData}
    setFormData({...currState, routeType: {value: e.target.value}})
  }

  return (
    <FormControl component={"fieldset"} style={{marginTop: "1rem"}}>
      <StyledFormLabel
        component="legend"
      >
        Route Type
      </StyledFormLabel>
      <RadioGroup row name="route-type" value={formData.routeType.value}>
        <FormControlLabel
          className={radioButtonLabel}
          value="Practical"
          control={<StyledRadio color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Practical</Typography>}
        />
        <FormControlLabel
          className={radioButtonLabel}
          value="Shortest"
          control={<StyledRadio className={radioButton} color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Shortest</Typography>}
        />
        <FormControlLabel
          className={radioButtonLabel}
          value="Fastest"
          control={<StyledRadio className={radioButton} color={radioColor} onClick={(e)=>handleChange(e)}/>}
          label={<Typography variant={"caption"}>Fastest</Typography>}
        />
      </RadioGroup>
    </FormControl>
  )
}