import { useState, useEffect } from "react";

export default function useLocalStorageState(key) {
  // piece of state based off value in local storage or false if local
  // storage key not found. Currently only used to store theme pref setting
  const [state, setState] = useState(() => {
    let val;
    try {
      val = JSON.parse(window.localStorage.getItem(key) || false);
    } catch (e) {
      val = false;
    }
    return val;
  });
  // use useEffect to update local storage when state changes
  useEffect(() => {
    window.localStorage.setItem(key, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return [state, setState];
}
