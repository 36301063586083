const CLIENT_ID = process.env.CLIENT_ID || '0oasi1ukk52a7jHOk0h7';
const ISSUER = process.env.ISSUER || 'https://arrivelogistics.oktapreview.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || true;

export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: 'http://localhost:3000/implicit/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    }
};