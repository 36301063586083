import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: { height: "100vh", width: "100vw" },
    [theme.breakpoints.down("xs")]: {maxWidth: "767px"}
  }
    // TODO: create a specific margins for different sized displays, possible
    // need of media queries for specific view port sizes
}));

export default function PageContent(props) {
  const { isDarkMode } = useContext(ThemeContext);
  const classes = useStyles();
  const styles = {
    backgroundColor: isDarkMode ? "#292929" : "#fff",
  };
  return (
    <div className={classes.root} style={styles}>
      {props.children}
    </div>
  );
}
