import React, { useContext } from "react";
import { Button, FormControlLabel, Grid } from "@material-ui/core";
import { ThemeContext } from "../contexts/ThemeContext";
import { FormContext, initialFormData } from "../contexts/FormContext";
import { formStyles } from "../styles/formStyle";
import ExtraStops from "./ExtraStops";
import VersionMenu from "./VersionMenu";
import { DarkStyledSwitch, LightStyledSwitch } from "./StyledSwitch";
import { DarkTextField, LightTextField } from "./StyledTextField";
import { v4 as uuid } from "uuid";
import {getMiles, createMap} from "./api";
import RouteType from "./RouteType";


export default function Form() {
  // Contexts
  const { isDarkMode } = useContext(ThemeContext);
  const {
    fields,
    dispatch,
    formData,
    setFormData,
    setResultData,
    setIsMapHidden,
  } = useContext(FormContext);

  // Styles
  const classes = formStyles();
  const StyledSwitch = isDarkMode ? DarkStyledSwitch : LightStyledSwitch;
  const StyledTextField = isDarkMode ? DarkTextField : LightTextField;

  const handleSubmit = async () => {
    const payload = {
      data: {
        stops: [
          {id: "origin", value: formData.origin.value},
          ...fields,
          {id: "destination", value: formData.destination.value},
        ],
        options: {
          tolls: formData.tolls,
          openBorders: formData.openBorders,
          pcmVersion: formData.pcmVersion,
          routeType: formData.routeType
          },
        }
      }
      // async Axios post to api
    const response = await getMiles(payload.data)


    if (response.errors === true) {
      // do error stuff here
      const stops = response.stops;
      const origin = stops[0];
      const destination = stops[(stops.length - 1)];
      const options = response.options;

      setFormData({origin, destination, ...options})
      if (stops.length > 2) {
        const extraStops = stops.splice(1, (stops.length - 2));
        console.log(extraStops)
        dispatch({type: "ERROR", value:extraStops})
      }
      return null;
      }

      setIsMapHidden(false)
      createMap(response.coords, response.options)
      setResultData(response)
      // setFormData(initialFormData)
      dispatch({type: "CLEAR"})
      return null;
  }


  return (

    <Grid container direction="column">
      <form className={classes.root} autoComplete="off">
        {/* Origin input field */}
        <Grid item style={{ position: "relative" }}>
          <StyledTextField
            error={formData.origin.error }
            helperText={formData.origin.error ? 'Address entered was not found': null}
            FormHelperTextProps={isDarkMode ? {className: classes.darkFormErrorText}: null}
            className={classes.formControl}
            label="Origin"
            id="origin"
            value={formData.origin.value}
            onChange={(e) => {
              setFormData({...formData, origin: {value: e.target.value}})
              }
            }
          />
        </Grid>

        {/* Component that dynamically generated stops via "add a stop" button */}
        <ExtraStops />

        {/* Destination input field */}
        <Grid item style={{marginTop: ".5rem"}}>
          <StyledTextField
            error={formData.destination.error}
            helperText={formData.destination.error ? 'Address entered was not found': null}
            FormHelperTextProps={isDarkMode ? {className: classes.darkFormErrorText}: null}
            className={classes.formControl}
            inputProps={{
              className: isDarkMode
                ? classes.darkFormField
                : classes.lightFormField,
            }}
            label="Destination"
            id="destination"
            value={formData.destination.value}
            onChange={(e) =>
              setFormData({
                ...formData,
                destination: { value: e.target.value },
              })
            }

          />

          {/* Generates Extra stops fields onclick, after 3 the button is no longer available */}

          <Grid item style={{ position: "relative", left: "11.5rem" }}>
            <Button
              className={
                isDarkMode
                  ? classes.darkAddFieldButton
                  : classes.lightAddFieldButton
              }
              size="small"
              onClick={() => {
                const id = uuid()
                dispatch({type: "ADD", id: id})
              }}
              disabled={fields.length >= 3}
            >
              Add Stop
            </Button>
          </Grid>

        </Grid>

        {/* PCMVersion Drop Down */}
        <VersionMenu/>



        {/* Tolls and border switches, possible todo in creating as separate component to slim down
        form component */}
        <Grid item>
          <FormControlLabel
            classes={{ label: classes.sliderLabel }}
            control={
              <StyledSwitch
                checked={formData.tolls.value}
                onClick={() =>
                  setFormData({
                    ...formData,
                    tolls: { value: !formData.tolls.value },
                  })
                }
              />
            }
            label={!formData.tolls.value ? "Allow Tolls" : "Avoiding Tolls"}
            style={{
              color: isDarkMode ? "#efefef" : "#9da1a7",
            }}
          />
          <FormControlLabel
            classes={{ label: classes.sliderLabel }}
            control={
              <StyledSwitch
                checked={formData.openBorders.value}
                onClick={() =>
                  setFormData({
                    ...formData,
                    openBorders: { value: !formData.openBorders.value },
                  })
                }
              />
            }
            label={
              formData.openBorders.value ? "Opened Borders" : "Closed Borders"
            }
            size="large"
            style={{ color: isDarkMode ? "#efefef" : "#9da1a7" }}
          />
        </Grid>

        {/* RouteType checkboxes*/}
        <RouteType/>

        <Grid item>
          <Button
            className={isDarkMode ? classes.darkButton : classes.lightButton}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Search
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
