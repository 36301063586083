import React, { useContext } from "react";
import { Button, FormControlLabel, Grid } from "@material-ui/core";
import { FormContext, initialFormData } from "../contexts/FormContext";
import { formStyles } from "../styles/accelerateFormStyles";
import ExtraStops from "./AccelerateExtraStops";
import VersionMenu from "./AccelerateVersionMenu";
import { LightStyledSwitch } from "./StyledSwitch";
import { LightTextField } from "./StyledTextField";
import { v4 as uuid } from "uuid";
import {getMiles} from "./api";
import RouteType from "./AccelerateRouteType";


export default function AccelerateForm() {
  // Contexts
  const {
    fields,
    dispatch,
    formData,
    isAccelerateFormHidden,
    setFormData,
    setResultData,
    setIsAccelerateFormHidden
  } = useContext(FormContext);

  // Styles
  const classes = formStyles();
  const StyledSwitch = LightStyledSwitch;
  const StyledTextField = LightTextField;

  const handleSubmit = async () => {
    const payload = {
      data: {
        stops: [
          {id: "origin", value: formData.origin.value},
          ...fields,
          {id: "destination", value: formData.destination.value},
        ],
        options: {
          tolls: formData.tolls,
          openBorders: formData.openBorders,
          pcmVersion: formData.pcmVersion,
          routeType: formData.routeType
        },
      }
    }
    // async Axios post to api
    const response = await getMiles(payload.data)

    if (response.errors === true) {
      // do error stuff here
      const stops = response.stops;
      const origin = stops[0];
      const destination = stops[(stops.length - 1)];
      const options = response.options;

      setFormData({origin, destination, ...options})
      if (stops.length > 2) {
        const extraStops = stops.splice(1, (stops.length - 2));
        console.log(extraStops)
        dispatch({type: "ERROR", value:extraStops})
      }
      return null;
    }
    setIsAccelerateFormHidden(true);
    setResultData(response)
    setFormData(initialFormData)
    dispatch({type: "CLEAR"})
    return null;
  }


  return (

    <Grid container direction="column" style={{display: isAccelerateFormHidden ? "none" : null}}>
      <form autoComplete="off">
        {/* Origin input field */}
        <Grid item style={{ position: "relative" }}>
          <StyledTextField
            size="small"
            error={formData.origin.error }
            helperText={formData.origin.error ? 'Address entered was not found': null}
            className={classes.formControl}
            inputProps={{
              className: classes.lightFormField,
            }}
            label="Origin"
            id="origin"
            value={formData.origin.value}
            onChange={(e) => {
              setFormData({...formData, origin: {value: e.target.value}})
            }
            }
          />
        </Grid>

        {/* Component that dynamically generated stops via "add a stop" button */}
        <ExtraStops />

        {/* Destination input field */}
        <Grid item>
          <StyledTextField
            size="small"
            error={formData.destination.error}
            helperText={formData.destination.error ? 'Address entered was not found': null}
            className={classes.formControl}
            inputProps={{
              className: classes.lightFormField,
            }}
            label="Destination"
            id="destination"
            value={formData.destination.value}
            onChange={(e) =>
              setFormData({
                ...formData,
                destination: { value: e.target.value },
              })
            }

          />

          {/* Generates Extra stops fields onclick, after 3 the button is no longer available */}

          <Grid item style={{ position: "relative", left: "11.5rem" }}>
            <Button
              className={classes.lightAddFieldButton}
              size="small"
              onClick={() => {
                const id = uuid()
                dispatch({type: "ADD", id: id})
              }}
              disabled={fields.length >= 2}
            >
              Add Stop
            </Button>
          </Grid>

        </Grid>

        {/* PCMVersion Drop Down */}
        <VersionMenu style={{marginTop: "-.25rem"}}/>



        {/* Tolls and border switches, possible todo in creating as separate component to slim down
        form component */}
        <Grid item>
          <FormControlLabel
            classes={{ label: classes.sliderLabel }}
            control={
              <StyledSwitch
                checked={formData.tolls.value}
                onClick={() =>
                  setFormData({
                    ...formData,
                    tolls: { value: !formData.tolls.value },
                  })
                }
              />
            }
            label={formData.tolls.value ? "Avoiding Tolls" : "Including Tolls"}
            style={{
              color: "#9da1a7",
            }}
          />
          <FormControlLabel
            classes={{ label: classes.sliderLabel }}
            control={
              <StyledSwitch
                checked={formData.openBorders.value}
                onClick={() =>
                  setFormData({
                    ...formData,
                    openBorders: { value: !formData.openBorders.value },
                  })
                }
              />
            }
            label={
              formData.openBorders.value ? "Opened Borders" : "Closed Borders"
            }
            size="large"
            style={{ color: "#9da1a7" }}
          />
        </Grid>

        {/* RouteType checkboxes*/}
        <RouteType/>

        <Grid item>
          <Button
            className={classes.lightButton}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Search
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
