import useLocalStorageState from "./useLocalStorageState";

// Hook used to toggle state of switch, hooked into
// localstorage hook to save data toggled to local storaged
export default function (initialVal = false) {
  const [state, setState] = useLocalStorageState("isDarkMode", initialVal);
  const toggle = () => {
    setState(!state);
  };
  return [state, toggle];
}
