import Axios from 'axios';

const auth = {Authorization: '2c69108527364b45842911eafef4687a'}

const searchLocation = async (location) => {
  const url = "https://singlesearch.alk.com/na/api/search";
  const query = {query: location};

  try {
    const response = await Axios.get(url, {params: query, headers: auth});
    const data = response.data

    if (data.Err === 0) {
      const fullAddress = data.Locations[0].Address;
      const address = fullAddress.StreetAddress;
      const city = fullAddress.City;
      const state = fullAddress.State;
      const zipCode = fullAddress.Zip;
      const coords = data.Locations[0].Coords;

      return {
        lon: coords.Lon,
        lat: coords.Lat,
        address,
        city,
        state,
        zipCode,
        error: false
      }
    } else {
      return {
        error: true
      }
    }
  } catch (e) {
    return {
      error: true
    }
  }
}

export const createMap = (coordinates, options) => {
  const mapArea = document.querySelector("#map");
  mapArea.innerHTML = "";
  mapArea.className = "";

  window.ALKMaps.APIKey = "2c69108527364b45842911eafef4687a";
  const map = new window.ALKMaps.Map("map");
  const layer = new window.ALKMaps.Layer.BaseMap(
    "ALK Maps",
    {},
    { displayInLayerSwitcher: false }
  );
  const routingLayer = new window.ALKMaps.Layer.Routing("Route Layer");
  map.addLayer(layer);
  map.setCenter();
  map.addLayer(routingLayer);

  for (let control of map.controls) {
    control.deactivate();
  }

  const coords = [];

  for (let c of coordinates) {
    let [lon, lat] = c.split(",");
    coords.push(new window.ALKMaps.LonLat(lon, lat))
  }

  const stops = window.ALKMaps.LonLat.transformArray(
    coords,
    new window.ALKMaps.Projection("EPSG:4326"),
    map.getProjectionObject()
  );
  routingLayer.addRoute({
    stops,
    functionOptions: {
      routeId: "app-map",
    },
    routeOptions: {
      routingType: options.routeType.value,
    },
    reportOptions: {
      dataVersion: options.pcmVersion.value,
    },
  });
};

export const getMiles = async (data) => {
  const url = 'https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/route/routeReports'
  const allStops = data.stops;
  const options = data.options;
  const allCoords = [];
  const resultStops = [];
  let errors = 0;

  for (let stop of allStops) {
    const result = await searchLocation(stop.value);
    const {lon, lat} = result;
    if (result.error === true) {
      errors += 1
    }
    resultStops.push({...stop, ...result});
    allCoords.push(`${lon},${lat}`);
  }

  if (errors > 0) {
    return {stops: resultStops, errors: true, options: options};
  }
  const coords = allCoords.join(';');

  const searchParams = {
    stops: coords,
    reports: "CalcMiles",
    dataVersion: options.pcmVersion.value,
    routeType: options.routeType.value,
    avoidTolls: options.tolls.value,
    openBorders: options.openBorders.value
  }

  const milesResponse = await Axios.get(url, {params: searchParams, headers: auth});
  const resultMiles = milesResponse.data[0].TMiles;
  return {miles: resultMiles, options: options, stops: resultStops, coords: allCoords, errors: false}
}
