import { makeStyles } from "@material-ui/styles";

export const mobileDataDisplayStyles = makeStyles((theme) => ({
  root: {
    padding: ".5rem",
    margin: "3rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #9da1a7",
    borderRadius: "5px",
    minWidth: "250px",
    minHeight: "200px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  accelerateRoot: {
    padding: ".5rem",
    // margin: "3rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #9da1a7",
    borderRadius: "5px",
    width: "250px",
    // height: "200px",
  },

  gridRow: {
    display: "flex",
    marginLeft: ".5rem",
  },

  accelerateDivider: {
    margin: ".25rem 0 .25rem 0",
    // minWidth: "250px",
    color: "#9da1a7",
  },

  divider: {
    margin: ".25rem 0 .25rem 0",
    minWidth: "250px",
    color: "#9da1a7",
  },

  lightButton: {
    marginTop: "1rem",
    background: "#2aaae2",
    color: "#fff",
    "&:hover": { backgroundColor: "#2aaae2" },
  },

  darkModeText: {
    color: "#efefef",
  },
  lightModeText: {
    color: "#292929",
  },

  lightOrigin: {
    backgroundColor: "#e8f5e9",
    color: "#4caf50",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 .5rem",
  },
  lightStop: {
    backgroundColor: "#feefcb",
    color: "#f1ae11",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 .5rem",
  },
  lightDestination: {
    backgroundColor: "#ffedf1",
    color: "#cb2024",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 .5rem",
  },
  lightMiles: {
    color: "#9da1a7",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 .5rem",
  },
}));
