import React, { useEffect } from 'react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import config from './config';

const Login = () => {
    useEffect(() => {
        const { pkce, issuer, clientId, redirectUri, scopes } = config.oidc;
        const widget = new OktaSignIn({
            baseUrl: 'https://arrivelogistics.oktapreview.com/',
            clientId,
            redirectUri,
            authParams: {
                pkce,
                issuer,
                display: 'page',
                responseMode: pkce ? 'query' : 'fragment',
                scopes,
            },
        });

        widget.renderEl(
            { el: '#sign-in-widget' },
            () => {
                /**
                 * In this flow, the success handler will not be called beacuse we redirect
                 * to the Okta org for the authentication workflow.
                 */
            },
            (err) => {
                throw err;
            },
        );
    }, []);

    return (
        <div>
            <div id="sign-in-widget" />
        </div>
    );
};
export default Login;