import React, { createContext } from "react";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles"
import useToggleState from "../hooks/useToggleState";

const theme = createMuiTheme({
  palette: {
    primary : {
      main: "#efefef"
    },

    secondary: {
      main: '#2aaae2',
    },

    info: {
      main: "#9da1a7"
    }
  },
  overrides: {
    MuiInput: {
      root: {
        width: "265px",
        color: "#9da1a7"
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #9da1a7'
        },
        '&:after': {
          borderBottom: '2px solid #9da1a7'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '2px solid #9da1a7'
        },
      },
    },
    MuiSelect: {
      color: "#9da1a7",
      icon: {
        color: "#9da1a7"
      },
    },
  },
});

export const ThemeContext = createContext(null);

export function MainTheme(props) {
  const [isDarkMode, toggleTheme] = useToggleState(
    window.localStorage.getItem("isDarkMode") || false
  );
  return (
    <ThemeProvider  theme={theme}>
      <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
        {props.children}
      </ThemeContext.Provider>
    </ThemeProvider >
  );
}