import { makeStyles } from "@material-ui/styles";

export const dataDisplayStyles = makeStyles((theme) => ({
  root: {
    padding: ".5rem",
    margin: "3rem 1rem 1rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #9da1a7",
    borderRadius: "5px",
    minWidth: "250px",
    minHeight: "200px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  gridRow: {
    display: "flex",
    marginLeft: ".5rem",
  },

  results: {
    marginLeft: "1rem",
  },

  divider: {
    margin: ".5rem 0 .5rem 0",
  },

  darkOrigin: {
    backgroundColor: "#e8f5e9",
    color: "#4caf50",
    textAlign: "center",
    borderRadius: "20px",
  },
  darkStop: {
    backgroundColor: "#ffedf1",
    color: "#cb2024",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "20px",
  },
  darkDestination: {
    backgroundColor: "#ffedf1",
    color: "#cb2024",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "20px",
  },

  lightOrigin: {
    backgroundColor: "#e8f5e9",
    color: "#4caf50",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 1rem",
  },
  lightStop: {
    backgroundColor: "#feefcb",
    color: "#f1ae11",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 1rem",
  },
  lightDestination: {
    backgroundColor: "#ffedf1",
    color: "#cb2024",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 1rem",
  },
  lightMiles: {
    color: "#9da1a7",
    textAlign: "center",
    alignSelf: "center",
    borderRadius: "20px",
    margin: "0 .5rem 0 1rem",
  },
}));

// import { makeStyles } from "@material-ui/core/styles";

// export const dataDisplayStyles = makeStyles((theme) => ({
//   root: {
//     padding: ".5rem",
//     margin: "3rem 1rem 0 0",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "1px solid #9da1a7",
//     borderRadius: "5px",
//     minHeight: "200px",
//     [theme.breakpoints.down("sm")]: {
//       display: "none",
//     },
//   },

//   test: {
//     fontSize: "10px",
//   },

//   gridRow: { marginTop: "1rem" },

//   iconDivider: {
//     margin: ".5rem 0 .5rem 1rem",
//     color: "#9da1a7",
//   },

//   darkOrigin: {
//     backgroundColor: "#e8f5e9",
//     color: "#4caf50",
//     textAlign: "center",
//     borderRadius: "20px",
//   },
//   darkStop: {
//     backgroundColor: "#ffedf1",
//     color: "#cb2024",
//     alignContent: "center",
//     textAlign: "center",
//     borderRadius: "20px",
//   },
//   darkDestination: {
//     backgroundColor: "#ffedf1",
//     color: "#cb2024",
//     alignContent: "center",
//     textAlign: "center",
//     borderRadius: "20px",
//   },

//   lightOrigin: {
//     backgroundColor: "#e8f5e9",
//     color: "#4caf50",
//     alignSelf: "center",
//     borderRadius: "20px",
//     margin: "0 .5rem 0 .5rem",
//   },
//   lightStop: {
//     backgroundColor: "#feefcb",
//     color: "#f1ae11",
//     textAlign: "center",
//     alignSelf: "center",
//     borderRadius: "20px",
//     margin: "0 .5rem 0 .5rem",
//   },
//   lightDestination: {
//     backgroundColor: "#ffedf1",
//     color: "#cb2024",
//     textAlign: "center",
//     alignSelf: "center",
//     borderRadius: "20px",
//     margin: "0 .5rem 0 .5rem",
//   },
//   lightMiles: {
//     marginLeft: "1rem",
//   },
// }));
