import React, { useContext } from "react";
import { Box, Divider, Grid, Icon, Typography } from "@material-ui/core/";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import { mobileDataDisplayStyles } from "../styles/mobileDataDisplayStyles";
import { FormContext } from "../contexts/FormContext";
import {Button} from "@material-ui/core";


export default function MobileDataDisplay() {
  // contexts
  const { resultData, setIsAccelerateFormHidden, setResultData } = useContext(FormContext);

  // styles
  const classes = mobileDataDisplayStyles();

  const handleClick = () => {
    setResultData(null)
    setIsAccelerateFormHidden(false)
  }

  return !!resultData && (
    <>
      <Box className={classes.accelerateRoot}>
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          {/* Origin Area */}
          <Grid item className={classes.gridRow}>
            <Icon className={classes.lightOrigin}>
              <LocationOnIcon />
            </Icon>
            <div style={{ marginLeft: "1rem" }}>
              <Typography className={classes.lightModeText} variant="subtitle2">
                <strong>
                  Origin
                </strong>
              </Typography>
              <Typography className={classes.lightModeText} variant="caption">
                {!!resultData.stops[0].address && (<>{resultData.stops[0].address}<br/></>)}
                {resultData.stops[0].city}, {resultData.stops[0].state} {resultData.stops[0].zipCode}
              </Typography>
            </div>
          </Grid>

          <Divider className={classes.accelerateDivider} />

          {resultData.stops.map((stop, idx) => {
            if (stop.id !== 'origin' && stop.id !== 'destination') {
              return (
                <React.Fragment key={stop.id}>
                  <Grid item className={classes.gridRow}>
                    <Icon className={classes.lightStop}>
                      <LocationOnIcon/>
                    </Icon>
                    <div style={{marginLeft: "1rem"}}>
                      <Typography variant="subtitle2">
                        <strong>Stop</strong>
                      </Typography>
                      <Typography variant="caption">
                        {!!resultData.stops[idx].address && (<>{resultData.stops[idx].address}<br/></>)}
                        {resultData.stops[idx].city}, {resultData.stops[idx].state} {resultData.stops[idx].zipCode}
                      </Typography>
                    </div>
                  </Grid>
                  <Divider className={classes.accelerateDivider} />
                </React.Fragment>
              )
            }
            return null
          })
          }

          {/* Destination data area */}
          <Grid item className={classes.gridRow}>
            <Icon className={classes.lightDestination}>
              <LocationOnIcon style={{ alignSelf: "bottom" }} />
            </Icon>
            <div style={{ marginLeft: "1rem" }}>
              <Typography className={classes.lightModeText} variant="subtitle2">
                <strong>
                  Destination
                </strong>
              </Typography>
              <Typography className={classes.lightModeText} variant="caption">
                {!!resultData.stops[resultData.stops.length - 1].address && (<>{resultData.stops[resultData.stops.length - 1].address}<br/></>)}
                {resultData.stops[resultData.stops.length - 1].city}, {resultData.stops[resultData.stops.length - 1].state} {resultData.stops[resultData.stops.length - 1].zipCode}
              </Typography>
            </div>
          </Grid>

          <Divider className={classes.accelerateDivider} />

          {/* Area displaying Miles */}
          <Grid item className={classes.gridRow}>
            <Icon className={classes.lightMiles} color="action">
              <LocalShippingRoundedIcon />
            </Icon>
            <div style={{ marginLeft: "1rem" }}>
              <Typography className={classes.lightModeText} variant="subtitle1">
                Total Miles
              </Typography>
              <Typography className={classes.lightModeText} variant="subtitle2">
                <strong>
                  {resultData.miles}
                </strong>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Button
        className={classes.lightButton}
        variant="contained"
        onClick={() => handleClick()}
      >
        New Search
      </Button>


    </>
  );
}
