import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

//TODO: find class that targets switch base on Mui component
// and add to formStyles jss file to use classes and eliminate
// this file

export const LightStyledSwitch = withStyles({
  switchBase: {
    color: "#2aaae2",
    "&$checked": {
      color: "#2aaae2",
    },
    "&$checked + $track": {
      backgroundColor: "#2aaae2",
    },
  },
  checked: {},
  track: { backgroundColor: "#9da1a7" },
})(Switch);

export const DarkStyledSwitch = withStyles({
  switchBase: {
    color: "#efefef",
    "&$checked": {
      color: "#efefef",
    },
    "&$checked + $track": {
      backgroundColor: "#53575e",
    },
  },
  checked: {},
  track: { backgroundColor: "#fff" },
})(Switch);
