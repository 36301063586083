import React from 'react';
import AcceleratePageContent from "./AcceleratePageContent";
import MobileDataDisplay from "./AccelerateDataDisplay";
import Form from "./AccelerateForm";
import {FormProvider} from "../contexts/FormContext";
import {MainTheme} from "../contexts/ThemeContext";

export default function () {
  return (
    <MainTheme>
      <AcceleratePageContent>
          <FormProvider>
          <MobileDataDisplay/>
          <Form/>
          </FormProvider>
      </AcceleratePageContent>
    </MainTheme>
  )
}