import React, { useContext } from "react";
import { Box, Grid, Icon, Typography} from "@material-ui/core/";
import {withStyles} from "@material-ui/styles"
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShipping";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { dataDisplayStyles } from "../styles/dataDisplayStyles";
import { FormContext } from "../contexts/FormContext";
import { ThemeContext } from "../contexts/ThemeContext";

/*TODO: Merge styles into dataDisplayStyles (refer to mobileDataDisplay styles)
 and add class to base typography component */

const DarkStyledTypography = withStyles({
  root: {
    color: "#efefef",
  },
})(Typography);

const LightStyledTypography = withStyles({
  root: {
    color: "#292929",
  },
})(Typography);

export default function DataDisplay() {
  // contexts
  const { resultData } = useContext(FormContext);
  const { isDarkMode } = useContext(ThemeContext);

  // styles
  const classes = dataDisplayStyles();
  const StyledTypography = isDarkMode
    ? DarkStyledTypography
    : LightStyledTypography;

  return !!resultData && (
    <Box className={classes.root}>
      <Grid container direction="row">
        <Grid
          item
          container
          sm={6}
          direction="row"
          justify="center"
          alignContent="center"
        >
          {/* Area displaying settings used on search */}
          <Grid container direction={"row"} style={{marginBottom: "1rem"}}>
            <Grid item className={classes.results} direction="column">
              <Icon className={classes.lightMiles} color="action">
                <TuneRoundedIcon />
              </Icon>
            </Grid>
            <Grid citem className={classes.results} direction="column">
                <StyledTypography variant="caption">
                  Version: {resultData.options.pcmVersion.value}
                </StyledTypography>
                <br />
                <StyledTypography variant="caption">
                  Route: {resultData.options.routeType.value}
                </StyledTypography>
                <br />
                <StyledTypography variant="caption">
                  Tolls: {resultData.options.tolls.value ? "Avoided" : "Allowed"}
                </StyledTypography>
                <br />
                <StyledTypography variant="caption">
                  Borders: {resultData.options.openBorders.value ? "Open" : "Closed"}
                </StyledTypography>
            </Grid>
          </Grid>


          <div className={classes.divider} />

          {/* Area displaying Miles */}
          <Grid container direction={"row"}>
            <Grid item className={classes.results} direction={"column"}>
              <Icon className={classes.lightMiles} color="action">
                <LocalShippingRoundedIcon />
              </Icon>
            </Grid>
            <Grid item className={classes.results} direction={"column"}>
                <StyledTypography variant="subtitle1">
                  Total Miles
                </StyledTypography>
                <StyledTypography variant="subtitle2">
                  <strong>
                    {resultData.miles}
                  </strong>
                </StyledTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sm={6}
          item
          container
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          {/* Origin Area */}
          <Grid item className={classes.gridRow}>
            <Icon className={classes.lightOrigin}>
              <LocationOnIcon />
            </Icon>
            <div style={{ marginLeft: "1rem" }}>
              <StyledTypography variant="subtitle2"><strong>Origin</strong></StyledTypography>
              <StyledTypography style={{fontSize:"10px"}}>
                {!!resultData.stops[0].address && (<>{resultData.stops[0].address}<br/></>)}
                {resultData.stops[0].city}, {resultData.stops[0].state} {resultData.stops[0].zipCode}
              </StyledTypography>
            </div>
          </Grid>

          <div className={classes.divider} />

          {resultData.stops.map((stop, idx) => {
            if (stop.id !== 'origin' && stop.id !== 'destination') {
              return (
                <>
                  <Grid item className={classes.gridRow}>
                    <Icon className={classes.lightStop}>
                      <LocationOnIcon/>
                    </Icon>
                    <div style={{marginLeft: "1rem"}}>
                      <StyledTypography variant="subtitle2"><strong>Stop</strong></StyledTypography>
                      <StyledTypography style={{fontSize: "10px"}}>
                        {!!resultData.stops[idx].address && (<>{resultData.stops[idx].address}<br/></>)}
                        {resultData.stops[idx].city}, {resultData.stops[idx].state} {resultData.stops[idx].zipCode}
                      </StyledTypography>
                    </div>
                  </Grid>
                  <div className={classes.divider} />
                </>
              )
            }
            return null
          })
          }

          {(resultData.stops.length <= 2) && <br/>}



          <Grid item className={classes.gridRow}>
            <Icon className={classes.lightDestination}>
              <LocationOnIcon style={{ alignSelf: "bottom" }} />
            </Icon>
            <div style={{ marginLeft: "1rem" }}>
              <StyledTypography variant="subtitle2">
                <strong>
                  Destination
                </strong>
              </StyledTypography>
              <StyledTypography style={{fontSize:"10px"}}>
                {!!resultData.stops[resultData.stops.length - 1].address && (<>{resultData.stops[resultData.stops.length - 1].address}<br/></>)}
                {resultData.stops[resultData.stops.length - 1].city}, {resultData.stops[resultData.stops.length - 1].state} {resultData.stops[resultData.stops.length - 1].zipCode}
              </StyledTypography>
            </div>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}
