import React, {useContext} from 'react';
import {FormContext} from "../contexts/FormContext";
import {useMediaQuery} from "@material-ui/core";

const styles = {
    // width: "(auto - 1rem)",
    width: "438px",
    height: "250px",
    marginTop: "1rem",
    border: "1px solid #efefef",
    borderRadius: "5px",
    padding: ".5rem",

}


export default function Map() {
    const matches = useMediaQuery('(max-width:1000px)')
    const {isMapHidden} = useContext(FormContext);
    return <div id="map" style={styles} hidden={isMapHidden || matches}/>
}
