import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

// TODO: find class that targets underline and label on Mui input
// subcomponent and add to formStyles jss file to use classes and
// eliminate this file

export const LightTextField = withStyles({
  root: {"& .MuiInput-root": {
      color: "#2f3032"
    },
    "& .MuiInput-underline:hover::before": {
      borderBottomColor: "#9da1a7",
    },
    "& label.Mui-focused": {
      color: "#2aaae2",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2aaee2",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "red",
    },
    "& label.Mui-error.Mui-focused": {
      color: "red",
    },
    width: "265px"
  },
})(TextField);

export const DarkTextField = withStyles({
  root: {
    "& .MuiInput-root": {
      color: "#efefef"
    },
    "& label": {
      color: "#9da1a7",
    },
    "& label.Mui-error":{
      color: "#ffedf1"
    },

    "& label.Mui-focused": {
      color: "#efefef",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottomColor: "#efefef",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#9da1a7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#efefef",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#ffedf1",
    },
    "& label.Mui-error.Mui-focused": {
      color: "#ffedf1",
    },
    width: "265px"
  },
})(TextField);
