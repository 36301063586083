import React, { useContext } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { ThemeContext } from "../contexts/ThemeContext";

// Switch to change between dark theme and light theme, used for
// both mobile and desktop
export default function ThemeSlider() {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isDarkMode}
          name="themeMode"
          onClick={toggleTheme}
          color="default"
        />
      }
      label={isDarkMode ? "LightMode" : "DarkMode"}
    />
  );
}
