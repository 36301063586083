import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
      width: "325px",
      height: "500px",
      backgroundColor: "#fff",
      margin: ".5rem"
  }
}));

export default function AcceleratePageContent(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  );
}
