const reducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [...state, { id: action.id, value: "", error: false }];
    case "DELETE":
      return state.filter((field) => field.id !== action.id);
    case "UPDATE":
      return state.map((field) =>
        field.id === action.id ? { ...field, value: action.value, error: false } : field
      );
    case "CLEAR":
      return [];
    case "ERROR":
      return action.value;
    default:
      return state;
  }
};
export default reducer;
