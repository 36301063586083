import React from "react";
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import config from './components/config';
import Home from "./components/Home";
import CustomLoginComponent from './components/OktaSignInWidget';
import AccelerateView from "./components/AccelerateView";

const SecuredApp = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  return (
      <Security
          {...config.oidc}
          onAuthRequired={customAuthHandler}
      >
          <Route path="/accelerate" exact component={AccelerateView}/>
          <Route path="/" exact component={Home} />
          <Route path="/login" component={CustomLoginComponent} />
          <Route path="/implicit/callback" component={LoginCallback} />
      </Security>
  );
};

function App() {
  return (
      <div>
        <Router>
          <SecuredApp/>
        </Router>
      </div>
  )
  ;
}

export default App;